import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import OOI from './ooi'
import { CssFetcherSymbol, ICssFetcher, LifeCycle, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { OOISsrManagerSymbol, ReactLoaderForOOISymbol } from './symbols'
import { ooiComponentsRegistrar } from './ooiComponentsRegistrar'
import { ComponentsRegistrarSymbol } from '@wix/thunderbolt-components-loader'
import { WarmupDataEnricherSymbol } from 'feature-warmup-data'
import OOIPostSsrPropsEnricher from './ssr/ooiPostSsrPropsEnricher'
import OOISsrManager from './ssr/ooiSsrManager'
import { OoiCssFetcher } from './ooiCssFetcher'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler, WixCodeSdkHandlersProviderSym).to(OOI)
}

export const site: ContainerModuleLoader = (bind) => {
	if (process.env.browser) {
		bind(ReactLoaderForOOISymbol).to(require('./componentsLoaderClient').default)
		bind(LifeCycle.AppWillRenderFirstPageHandler, LifeCycle.AppDidMountHandler).to(OOIPostSsrPropsEnricher)
	} else {
		bind(ReactLoaderForOOISymbol).to(require('./componentsLoaderSSR').default)
		bind(OOISsrManagerSymbol, WarmupDataEnricherSymbol).to(OOISsrManager)
	}
	bind(ComponentsRegistrarSymbol).to(ooiComponentsRegistrar)
	bind<ICssFetcher>(CssFetcherSymbol).to(OoiCssFetcher)
}

export * from './types'
export * from './symbols'
