import _ from 'lodash'
import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import {
	IAppWillRenderFirstPageHandler,
	IAppDidMountHandler,
	IPropsStore,
	Props,
	IPlatform,
	PlatformSymbol,
} from '@wix/thunderbolt-symbols'
import { IWarmupDataProvider, WarmupDataProviderSymbol } from 'feature-warmup-data'
import { OOIWarmupData } from '../types'

export default withDependencies<IAppDidMountHandler & IAppWillRenderFirstPageHandler>(
	[WarmupDataProviderSymbol, Props, optional(PlatformSymbol)],
	(warmupDataProvider: IWarmupDataProvider, props: IPropsStore, platform?: IPlatform) => ({
		appWillRenderFirstPage: async () => {
			const ooiWarmupData = await warmupDataProvider.getWarmupData<OOIWarmupData>('ooi')
			_.forEach(ooiWarmupData?.failedInSsr, (__, compId) => {
				props.update({
					[compId]: {
						__VIEWER_INTERNAL: {
							failedInSsr: true,
						},
					},
				})
			})
			// update props store with props from warmup data before hydrating
			_.forEach(ooiWarmupData?.ssrPropsUpdates, ({ dataProps, functionPropsNames }, compId) => {
				const compProps = { ...dataProps }
				functionPropsNames.forEach((functionName) => {
					const functionPropBeforeRunningPlatform = async (...args: Array<unknown>) => {
						await platform?.ready()
						const functionPropAfterRunningPlatform = _.get(props.get(compId), functionName, _.noop)
						if (functionPropAfterRunningPlatform === functionPropBeforeRunningPlatform) {
							// prevent recursion if controller did not setProps({functionName}) during csr for some reason
							return
						}
						// invoke controller handler that was registered during hydration
						return functionPropAfterRunningPlatform(...args)
					}
					// the _.set() is there cause function props can be nested, i.e functionName = 'actions.reportBI'.
					_.set(compProps, functionName, functionPropBeforeRunningPlatform)
				})
				props.update({ [compId]: compProps })
			})
		},
		appDidMount: async () => {
			const [ooiWarmupData] = await Promise.all([
				warmupDataProvider.getWarmupData<OOIWarmupData>('ooi'),
				// on sad flow, wait for ooi controller to set props before rendering the ooi component
				platform?.ready(),
			])
			_.forEach(ooiWarmupData?.failedInSsr, (__, compId) => {
				props.update({
					[compId]: {
						__VIEWER_INTERNAL: {},
					},
				})
			})
		},
	})
)
