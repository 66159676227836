import _ from 'lodash'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	IPropsStore,
	Props,
	SiteFeatureConfigSymbol,
	pageIdSym,
	PageScrollRegistrarSymbol,
	SdkHandlersProvider,
	Experiments,
	ExperimentsSymbol,
	AppDidMountPromiseSymbol,
	PlatformSymbol,
	IPlatform,
} from '@wix/thunderbolt-symbols'
import { SiteScrollBlockerSymbol, ISiteScrollBlocker } from 'feature-site-scroll-blocker'
import { IPageScrollRegistrar } from 'feature-page-scroll'
import { name, OOISsrManagerSymbol } from './symbols'
import { OOIPageConfig, OOISiteConfig, OOISsrManager, OOIWarmupData, SetControllerProps } from './types'
import { createHostProps } from './hostProps'
import { OoiTpaSharedConfigSymbol, IOoiTpaSharedConfig } from 'feature-ooi-tpa-shared-config'
import { IWarmupDataProvider, WarmupDataProviderSymbol } from 'feature-warmup-data'

export default withDependencies(
	[
		pageIdSym,
		named(PageFeatureConfigSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		Props,
		SiteScrollBlockerSymbol,
		PageScrollRegistrarSymbol,
		OoiTpaSharedConfigSymbol,
		ExperimentsSymbol,
		AppDidMountPromiseSymbol,
		WarmupDataProviderSymbol,
		optional(OOISsrManagerSymbol),
		optional(PlatformSymbol),
	],
	(
		pageId,
		{ ooiComponents, accessibilityEnabled }: OOIPageConfig,
		{ viewMode, formFactor }: OOISiteConfig,
		propsStore: IPropsStore,
		siteScrollBlocker: ISiteScrollBlocker,
		{ registerToThrottledScroll }: IPageScrollRegistrar,
		{ getFontsConfig }: IOoiTpaSharedConfig,
		experiments: Experiments,
		appDidMountPromise: Promise<unknown>,
		warmupDataProvider: IWarmupDataProvider,
		ooiSsrManager?: OOISsrManager,
		platform?: IPlatform
	): IPageWillMountHandler & SdkHandlersProvider<{ setControllerProps: SetControllerProps }> => {
		return {
			getSdkHandlers() {
				return {
					setControllerProps(controllerCompId, controllerDataProps, functionNames, invokeFunction) {
						if (experiments['specs.thunderbolt.do_not_wait_for_platform_on_first_render2']) {
							ooiSsrManager?.updateSsrProps(controllerCompId, controllerDataProps, functionNames) // eslint-disable-line no-unused-expressions
						}

						functionNames.forEach((functionName) =>
							_.set(controllerDataProps, functionName, (...args: any) =>
								invokeFunction(functionName, args)
							)
						)

						const props = { [controllerCompId]: controllerDataProps }
						if (
							process.env.browser &&
							experiments['specs.thunderbolt.do_not_wait_for_platform_on_first_render2']
						) {
							warmupDataProvider.getWarmupData<OOIWarmupData>('ooi').then((ooiWarmupData) => {
								if (ooiWarmupData) {
									// queue props to be flushed after hydration. ooiSsrManager updates props store with props from ssr before hydration.
									appDidMountPromise.then(() => propsStore.update(props))
								} else {
									// client side fallback
									propsStore.update(props)
								}
							})
							return
						}
						propsStore.update(props)
					},
				}
			},
			async pageWillMount() {
				ooiComponents.forEach((compData) => {
					const hostProps = createHostProps({
						compData,
						pageId,
						accessibilityEnabled,
						formFactor,
						viewMode,
						siteScrollBlocker,
						registerToThrottledScroll,
						fonts: getFontsConfig(),
						experiments,
						platform,
					})

					propsStore.update({
						[compData.compId]: {
							host: hostProps,
						},
					})
				})
			},
		}
	}
)
